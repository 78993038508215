<template>
  <div id="myselfSetting">
    <Back title="设置"></Back>
    <main>
      <van-cell-group>
        <!-- <van-cell title="修改密码" is-link /> -->
        <van-cell title="关于我们" v-model="versions" @click="onVersions" />
        <van-cell
          title="隐私条款"
          value="查看详情"
          is-link
          @click="linkTo('/myself/myselfPrivacy')"
        />
      </van-cell-group>
    </main>
  </div>
</template>

<script>
import Back from "@/components/back";
import { Dialog, Toast } from "vant";
export default {
  components: { Back },
  data() {
    return {
      versions: "版本v1.0",
    };
  },
  methods: {
    // 【监听】版本
    onVersions() {
      Toast("当前" + this.versions);
    },

    // 【监听】隐私条款
    linkTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
#myselfSetting {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow: auto;
}
</style>